
import { Component, Prop, Vue } from 'vue-property-decorator';
import MessageHeader from '@/components/views/ChatView/MessageHeader.vue';
import ActivityMessage from '@/components/views/ChatView/ActivityMessage.vue';
import { UserRepository } from '@/repositories/UserRepository';
import ChatMessage from '@/models/ChatMessage';
import PlainMessageComponent from '@/components/views/ChatView/PlainMessageComponent.vue';
import { getActionsForType } from '@/helpers/chat/ChatHelper';

@Component({
    name: 'Message',
    components: {
        PlainMessageComponent,
        MessageHeader,
        ActivityMessage,
    },
})
export default class MessageItem extends Vue {
    @Prop({required: true}) private message!: ChatMessage;
    @Prop({required: true}) private selectedProjectId!: null | string;
    @Prop({required: true}) private shouldShowActions!: boolean;

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }


    private get isMadeByUserSameAsCurrentUser() {
        if (this.currentUser == null || this.message.madeByUser == null) {
            return false;
        }

        return this.currentUser.id === this.message.madeByUser.id;
    }

    private getActionsForKebab(type: string) {
        if (this.selectedProjectId == null || !this.shouldShowActions) {
            return [];
        }
        return getActionsForType({
            type,
            projectId: this.selectedProjectId,
            nextStepId: this.message.nextStep == null ? null : this.message.nextStep.id,
            plainMessageId: this.message.content == null ? null : this.message.content.id,
            messageId: this.message.id,
            isMadeByUserSameAsCurrentUser: this.isMadeByUserSameAsCurrentUser,
        });
    }
}
