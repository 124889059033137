
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChatActionItem } from '@/interfaces/components/chat/ChatActionItem';

@Component({
    name: 'ChatAction',
})
export default class ChatAction extends Vue {
    @Prop({required: true}) private chatActionItem!: ChatActionItem;
}
