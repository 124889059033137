
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChatActionItem } from '@/interfaces/components/chat/ChatActionItem';
import ChatKebabOptions from '@/components/views/ChatView/ChatKebabOptions.vue';
import { generateMessageHeaderString } from '@/helpers/chat/ChatHelper';
import { UserRepository } from '@/repositories/UserRepository';
import ChatMessage from '@/models/ChatMessage';

@Component({
    name: 'MessageHeader',
    components: {
        ChatKebabOptions,
    },
})
export default class MessageHeader extends Vue {
    @Prop({required: true}) private chatActionList!: ChatActionItem[];
    @Prop({required: true}) private message!: ChatMessage;
    @Prop({required: true}) private type!: string;
    @Prop({required: true}) private isMadeByUserSameAsCurrentUser!: boolean;

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private get messageHeaderInfo() {
        if (this.currentUser == null) {
            return '';
        }

        return generateMessageHeaderString(this.message, this.currentUser, this.type);
    }
}
