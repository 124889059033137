
import { Vue, Component, Prop } from 'vue-property-decorator';
import { convertFileToAntdFile } from '@/helpers/CmsIndexHelper';
import { UploadFile } from 'ant-design-vue/types/upload';
import Attachment from '@/models/Attachment';
import Image from '@/models/Image';
// @ts-ignore
import downloadFile from 'downloadjs';

@Component({
    name: 'AttachmentList',
    components: {},
})
export default class AttachmentList extends Vue {
    @Prop({ required: true }) private attachments!: Attachment[];
    @Prop({ default: true }) private isDisabled!: boolean;

    private get antdAttachments() {
        return this.attachments.map((attachment: Attachment) => convertFileToAntdFile(attachment));
    }

    private async onPreview(file: UploadFile & Image) {
        let blob;
        try {
            blob = await Attachment.getBlob(file.url as string);
        } catch (e) {
            let error;

            if (e instanceof Error) {
                error = e.message;
            } else {
                error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
            }

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error,
            });
            return;
        }
        downloadFile(blob.data, file.name);
    }

    private onRemove(file: UploadFile) {
        this.$emit('removeFile', file);
    }
}
