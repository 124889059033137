var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"activity-message-wrapper"},[_c('div',{staticClass:"row"},[_c('router-link',{staticClass:"u-b0",attrs:{"to":{
                name: _vm.routeNames.project,
                params: {
                    id: _vm.selectedProjectId,
                },
                query: {
                    initialTab: `${_vm.projectTabValues.Basic}`
                }
            },"data-test":"activity-message-name"},scopedSlots:_vm._u([{key:"default",fn:function({href}){return [_c('a',{attrs:{"href":href}},[_vm._v(_vm._s(_vm.activity.name))])]}}])})],1),_c('div',{staticClass:"row"},[(_vm.activity.type != null)?_c('div',{staticClass:"cell",attrs:{"data-test":"activity-type"}},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('Tip sljedećeg koraka'))+" ")]),_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.activity.type.name)+" ")])]):_vm._e(),(_vm.activity.reminderOption != null)?_c('div',{staticClass:"cell",attrs:{"data-test":"reminder"}},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('Podsjetnik za sljedeći korak'))+" ")]),_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.activity.reminderOption.name)+" ")])]):_vm._e()]),_c('div',{staticClass:"row"},[(_vm.activity.reminderDate != null)?_c('div',{staticClass:"cell",attrs:{"data-test":"reminder-date"}},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('Vrijeme sljedećeg koraka'))+" ")]),_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm._f("dateTimeFormat")(_vm.activity.reminderDate))+" ")])]):_vm._e(),(_vm.activity.assignedUser != null)?_c('div',{staticClass:"cell",attrs:{"data-test":"assigned-user"}},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('Odgovorna osoba'))+" ")]),_c('span',{staticClass:"value"},[_c('span',{staticClass:"user"},[_vm._v(" @"+_vm._s(_vm.activity.assignedUser.name)+" ")])])]):_vm._e()]),_c('div',{staticClass:"row full-width"},[(_vm.activity.note !== '' && _vm.activity.note != null)?_c('div',{staticClass:"cell",attrs:{"data-test":"note"}},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('Poruka sljedećeg koraka'))+" ")]),_c('span',{staticClass:"value",domProps:{"innerHTML":_vm._s(_vm.activity.note)}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }