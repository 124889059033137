
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MessageItem from '@/components/views/ChatView/MessageItem.vue';
import ChatMessage from '@/models/ChatMessage';

@Component({
    name: 'ChatMessageList',
    components: {
        MessageItem,
    },
})
export default class ChatMessageList extends Vue {
    @Prop({ required: true }) private messageList!: ChatMessage[];
    @Prop({ required: true }) private selectedProjectId!: null | string;
    @Prop({ required: true }) private shouldShowActions!: boolean;

    @Watch('messageList', { deep: true, immediate: true })
    private onMessageListChange() {
        this.$nextTick(() => {
            const messageListElement = this.$refs.messageList as Element;
            messageListElement.scrollTop = messageListElement.scrollHeight - messageListElement.clientHeight;
        });
    }
}
