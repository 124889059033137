
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChatActionItem } from '@/interfaces/components/chat/ChatActionItem';
import ChatAction from '@/components/views/ChatView/ChatAction.vue';

@Component({
    name: 'ChatKebabOptions',
    components: {
        ChatAction,
    },
})
export default class ChatKebabOptions extends Vue {
    @Prop({required: true}) private chatActionList!: ChatActionItem[];
    @Prop({required: true}) private shouldButtonHaveBorder!: boolean;
    @Prop({default: false}) private isDisabled!: boolean;
}
