
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/enums/routes/RouteNames';
import PlainMessage from '@/models/PlainMessage';
import AttachmentList from '@/components/views/ChatView/AttachmentList.vue';

@Component({
    name: 'PlainMessage',
    components: {AttachmentList},
})
export default class PlainMessageComponent extends Vue {
    @Prop({required: true}) private selectedProjectId!: null | string;
    @Prop({required: true}) private plainMessage!: PlainMessage;

    private routeNames = RouteNames;
}
